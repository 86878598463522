module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 576px)","md":"(min-width: 768px)","l":"(min-width: 992px)","xl":"(min-width: 1200px)","portrait":"(orientaion: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s - Emprise Bank","title":"Emprise Bank","openGraph":{"type":"website","locale":"en_US","url":"https://www.emprisebank.com/","site_name":"Emprise Bank"},"twitter":{"site":"@EmpriseBank","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5407675f8f12ee23b70be4009a038490"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5THLG69","routeChangeEventName":"default-gatsby-route-change","includeInDevelopment":false,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["Content-Security-Policy: frame-ancestors 'self' https://*.kontent.ai https://app.kontent.ai","Referrer-Policy: strict-origin-when-cross-origin","Strict-Transport-Security: max-age=31536000; includeSubDomains; preload","Permissions-Policy: geolocation=(), camera=(), microphone=()"]},"mergeCachingHeaders":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
